body > iframe[style*="2147483647"] {
  display: none;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat.woff2") format("woff2");
}
body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #dfe9e8;
  font-size: 0.9em;
  overflow: hidden;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-radius: 0;
  border: 0px solid rgba(0, 0, 0, 0);
  -webkit-text-fill-color: #cacaca;
  -webkit-box-shadow: 0 0 0px 1000px #1f222b inset;
  transition: background-color 5000s ease-in-out 0s;
}

#scale {
  transform: scale(0.66);
  transform-origin: top left;
  position: absolute;
}
#input {
  width: 1920px;
  height: 1080px;
}
